<template>
  <ul class="users-list px-2">
    <li v-for="user in users" :key="user.id" class="pb-1">
      <b-media vertical-align="center" class="align-items-center">
        <template #aside>
          <b-avatar
            size="32"
            :src="`${user.logo}`"
            :text="avatarText(user.name)"
            :variant="`light-${resolveUserRoleVariant(user.role_name)}`"
          />
        </template>
        <b-media-body class="d-flex">
          <div>
            <b-link class="font-weight-bold d-block">
              {{ user.name }} - {{ user.role_name | establishmentRoles }}
            </b-link>
            <small class="text-muted text-break">{{ user.email }}</small>
            <div>
              <small class="text-muted">{{ user.phone_number }}</small>
            </div>
          </div>
          <b-dropdown
            variant="link"
            class="ml-auto"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'apps-users-edit', params: { id: user.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="disableUser(user.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Borrar</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-media-body>
      </b-media>
    </li>
  </ul>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useUsersList";

export default {
  components: {},
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    disableUser: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const { resolveUserRoleVariant } = useUsersList();

    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>
