<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Agregar nuevo usuario
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <div>
        <base-cropper
          :model="userData"
          @cropped-image="userData.logo = $event"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="José López"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Correo"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="JoséLopéz@mywalleat.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tel -->
          <validation-provider
            #default="validationContext"
            name="Cel"
            rules="required|min:10"
          >
            <b-form-group
              label="Teléfono celular"
              label-for="cel"
            >
              <b-form-input
                id="cel"
                v-model="userData.phone_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="Rol"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
                placeholder="Administrador"
                @input="userData.role_resource_id = ''"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Establishment -->
          <validation-provider
            v-if="userData.role_name === 'establishment_admin' || userData.role_name === 'store_clerk' || userData.role_name === 'establishment_presale_clerk' || userData.role_name === 'dispatcher' "
            #default="validationContext"
            name="Establishment"
            rules="required"
          >
            <b-form-group
              label="Establecimiento"
              label-for="establishment"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedEstablishment"
                :options="establishments"
                :reduce="val => val.id"
                label="name"
                placeholder="Tu establecimiento"
                @input="clearAndGetEstablishmentStores()"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Store -->
          <validation-provider
            v-if="userData.role_name === 'store_clerk' || userData.role_name === 'dispatcher'"
            #default="validationContext"
            name="Store"
            rules="required"
          >
            <b-form-group
              label="Tienda"
              label-for="Store"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role_resource_id"
                :options="stores"
                :reduce="val => val.id"
                label="name"
                placeholder="Tu tiendita"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Agregar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import { mapActions } from 'vuex'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BaseCropper from '@/@core/components/BaseCropper.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BaseCropper,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      establishments: [],
      stores: [],
      selectedEstablishment: '',
    }
  },
  setup({ emit }) {
    const blankUserData = {
      name: '',
      email: '',
      role_name: '',
      role_resource_id: '',
      logo: null,
      cel_number: '',
      phone_number: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      refFormObserver,
      getValidationState,
      resetForm,
      emit,
    }
  },
  beforeMount() {
    // fetch establishments: this is needed when you want to add a user with establishment_admin or store_clerk role
    const currentUser = JSON.parse(localStorage.getItem('userData'))
    if (currentUser.role_name === 'admin') {
      this.fetchEstablishments({
        meta: {
          pagination: {
            per_page: 1000,
          },
        },
      })
        .then(response => {
          this.establishments = response.data
        })
    } else {
      const currentEstablishment = currentUser.scoped_roles[0]
      this.selectedEstablishment = currentEstablishment.id
      this.establishments = [
        {
          id: currentEstablishment.role_resource_id,
          name: currentEstablishment.role_resource_name,
        },
      ]
    }
  },
  methods: {
    ...mapActions('app-user', ['fetchUsers']),
    ...mapActions('establishments', ['fetchEstablishments']),
    ...mapActions('stores', ['fetchStores']),
    clearAndGetEstablishmentStores() {
      if (this.userData.role_name === 'store_clerk' || this.userData.role_name === 'dispatcher') {
        this.userData.role_resource_id = ''
      } else {
        this.userData.role_resource_id = this.selectedEstablishment
      }
      this.fetchStores({ by_establishment: this.selectedEstablishment })
        .then(response => {
          this.stores = response.data
        })
    },
    onSubmit() {
      this.userData.cel_number = this.userData.customer ? `${52}${this.userData.phone_number}` : null
      this.userData.phone_number = `${52}${this.userData.phone_number}`
      store.dispatch('app-user/addUser', this.userData)
        .then(() => {
          this.fetchUsers({
            by_resource_id: this.$route.params.id,
          })
            .then(response => {
              this.$emit('new-users', response.data)
            })
          this.$emit('update:is-add-new-user-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Usuario ${this.userData.role_name} creado con exito`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `Se ha enviado un correo a ${this.userData.email} con las intrucciones para establecer su contraseña.`,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.messages[0],
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
